import React from 'react';
import { hydrate, render } from "react-dom";
import Privacy from './screens/Privacy';
import './index.css';
import DeleteAcc from './screens/DeleteAcc';
import DeleteReaderAcc from './screens/DeleteReaderAcc';
import Support from './screens/Support';
import Terms from './screens/Terms';
import Home from './screens/Home';
import TimeLeft from './screens/TimeLeft';
import DeleteSuccess from './screens/DeleteSuccess';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home lang="en" />,
  },
  {
    path: "/en",
    element: <Home lang="en" />,
  },
  {
    path: "/vi",
    element: <Home lang="vi" />,
  },
  {
    path: "/privacy",
    element: <Privacy />,
  },
  {
    path: "/time-left",
    element: <TimeLeft />,
  },
  {
    path: "/delete-account",
    element: <DeleteAcc />,
  },
  {
    path: "/delete-reader-account",
    element: <DeleteReaderAcc />,
  },
  {
    path: "/delete-success",
    element: <DeleteSuccess />,
  },
  {
    path: "/support",
    element: <Support />,
  },
  {
    path: "/terms",
    element: <Terms />,
  },
]);

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>, rootElement);
} else {
  render(<React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>, rootElement);
}
// root.render(
//   <React.StrictMode>
//     <RouterProvider router={router} />
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
